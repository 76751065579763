import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Row, Col, Checkbox, Select, Spin, Input, message } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';

import { useFetch } from 'services/hooks';
import { useWorkflow } from 'hooks/useWorkflow';

import { useProfileTemplate } from 'hooks/profileTemplate';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import Button from 'components/Button';
import InputMask from 'components/Form/InputMask';
import PageTitle from 'components/PageTitle';

import useCopyLinkModal from '../CopyLinkModal';

import ProfileForm from './components/ProfileForm';

import './styles.less';

const { Option, OptGroup } = Select;
const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index';

const CreateLinkType = ({ type, onClose, segment }) => {
  const { t: translate, i18n } = useTranslation();
  const { user } = useAuth();

  const { post: postOnboardingLink, loading: loadingOnboardingLink } = useFetch();

  const [form] = Form.useForm();
  const language = {
    'pt-BR': pt,
    'es-MX': es,
    'en-US': undefined,
  };

  const defaultCountry = {
    'pt-BR': 'br',
    'es-MX': 'mx',
    'en-US': 'us',
  };

  const {
    getWorkflowsData,
    data: _workflowsData,
    loading: loadingWorkflows,
  } = useWorkflow();

  const fetchWorkflows = useCallback(async () => {
    await getWorkflowsData({ tenantId: user?.tenantId });
  }, [getWorkflowsData, user]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const {
    loadAllProfileTemplates,
    allRecursiveProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const {
    loadOnboardingTemplates,
    loading: loadingOnboardingTemplate,
  } = useOnboardingTemplate();

  const { openModal: openCreateLinkModal, CreateLinkModal } = useCopyLinkModal();

  const [multipleShipments, setMultipleShipments] = useState(false);
  const [oneTimeLink, setOneTimeLink] = useState(false);
  const [oneTimeLinkClassName, setOneTimeLinkClassName] = useState(
    'one-time-link-form-item'
  );
  const [smsCheckbox, setSmsCheckbox] = useState(false);
  const [emailCheckbox, setEmailCheckbox] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [profileTemplate, setProfileTemplate] = useState(undefined);
  const [workflow, setWorkflowTemplate] = useState(undefined);

  useEffect(() => {
    if (oneTimeLink) {
      setOneTimeLinkClassName('');
    } else {
      setOneTimeLinkClassName('one-time-link-form-item');
    }
  }, [oneTimeLink]);

  const customPageTitle = useMemo(() => {
    let title;
    let subtitle;

    if (type === 'profile') {
      title = translate(
        `${I18N_BASE_PATH}.customPageTitle.title.profile` // => Novo link individual
      );
      subtitle = translate(
        segment === 'person'
          ? `${I18N_BASE_PATH}.customPageTitle.subtitle.profile`
          : `${I18N_BASE_PATH}.customPageTitle.subtitle.profile.company` // => Só pode ser preenchido pelos perfis vinculados, pode ser automaticamente encerrado após sua conclusão.
      );

      return { title, subtitle };
    }

    title = translate(
      `${I18N_BASE_PATH}.customPageTitle.title.shared` // => Novo link compartilhado
    );

    subtitle = translate(
      `${I18N_BASE_PATH}.customPageTitle.subtitle.shared` // => Link perpétuo que pode ser preenchido por qualquer pessoa sem uma meta de conclusão estipulada.
    );

    return { title, subtitle };
  }, [type, translate, segment]);

  const workflowsData = useMemo(() => {
    if (!segment || !_workflowsData) return _workflowsData;

    return {
      ..._workflowsData,
      docs: _workflowsData?.docs?.filter((wf) => wf?.segment === segment),
    };
  }, [_workflowsData, segment]);

  const customProfileTemplateLabel = useMemo(
    () => (
      <div className="custom-label-content">
        <span>
          {!hasFeatureFlag('isWorkflowBuilder', user)
            ? translate(
                `${I18N_BASE_PATH}.customProfileTemplateLabel.description` // => Modelo de consulta
              )
            : translate(
                `${I18N_BASE_PATH}.customProfileTemplateLabel.descriptionWorkflow` // => Workflow
              )}
        </span>
        {hasFeatureFlag('isWorkflowBuilder', user) ? (
          <Link to="/workflows">
            {translate(
              `${I18N_BASE_PATH}.customProfileTemplateLabel.linkWorkflow` // => Criar Workflow
            )}
          </Link>
        ) : (
          <Link to="/profile-templates/profile-models/registration">
            {translate(
              `${I18N_BASE_PATH}.customProfileTemplateLabel.link` // => Criar modelo
            )}
          </Link>
        )}
      </div>
    ),
    [translate, user]
  );

  const selectWorkflow = useCallback(
    (wf) => {
      const workflowSelected = workflowsData.docs.find((t) => t?._id === wf);

      setWorkflowTemplate(workflowSelected);
    },
    [workflowsData]
  );

  const allProfileTemplatesActive = useMemo(() => {
    const profileTemplatesFiltered = allRecursiveProfileTemplates?.filter(
      (p) => p?.active
    );

    return profileTemplatesFiltered || [];
  }, [allRecursiveProfileTemplates]);

  const groupedProfileTemplates = useMemo(() => {
    if (loadingAllProfileTemplates || !allProfileTemplatesActive) return null;

    const pfProfileTemplates = [];
    const pjProfileTemplates = [];

    allProfileTemplatesActive?.forEach((template) => {
      if (template.type === 'PF') pfProfileTemplates.push(template);
      if (template.type === 'PJ') pjProfileTemplates.push(template);
    });

    return { pfProfileTemplates, pjProfileTemplates };
  }, [allProfileTemplatesActive, loadingAllProfileTemplates]);

  const showProfileTemplateLabelType = useMemo(() => {
    const showPFProfileTemplates = allProfileTemplatesActive?.some(
      (p) => p?.type === 'PF'
    );

    const showPJProfileTemplates = allProfileTemplatesActive?.some(
      (p) => p?.type === 'PJ'
    );

    return { showPFProfileTemplates, showPJProfileTemplates };
  }, [allProfileTemplatesActive]);

  const handleSelectProfileTemplate = useCallback(
    (template) => {
      const templateSelected = allRecursiveProfileTemplates?.find(
        (t) => t?._id === template
      );

      setProfileTemplate(templateSelected);
    },
    [allRecursiveProfileTemplates]
  );

  useEffect(() => {
    form.setFieldsValue({
      shippingWay: ['manual'],
    });
  }, [form]);

  const validateTypeWorkflow = useMemo(() => {
    if (workflow?.segment === 'person') return 'PF';
    if (workflow?.segment === 'company') return 'PJ';

    return null;
  }, [workflow]);

  const handleSubmit = useCallback(
    async (values) => {
      const defaultTemplate = await loadOnboardingTemplates();
      const payload = {
        workflowId: workflow?._id,
        trustTemplateId: values?.profileTemplate,
        qsaTemplateId: profileTemplate?.qsaProfileTemplateId,
        templateId:
          profileTemplate?.onboardingTemplateId ||
          defaultTemplate[profileTemplate?.type]?._id,
        noExpire: multipleShipments,
        oneTimeLink,
        email: values?.email,
        type: profileTemplate?.type || validateTypeWorkflow,
        smsPhoneNumber: values?.sms,
        textMessage: translate(
          'components.modalShareProfileTemplateLink.shareLinkModal.textMessage'
        ),
        attributes: {
          cpf: values?.cpf,
          cnpj: values?.cnpj,
          name: values?.name,
          personId: values?.personId,
          companyId: values?.companyId,
        },
      };

      try {
        const response = await postOnboardingLink({
          url: '/onboardings',
          payload,
          showError: true,
        });

        onClose();

        message.success(response?.message);

        openCreateLinkModal({
          link: response?.onboardingUrl,
          type,
        });
      } catch (err) {
        // if (
        //   err?.response?.data?.message?.includes('a conta chegou ao limite de execuções')
        // ) {
        //   return message.error(err?.response?.data?.message);
        // }
      }
    },
    [
      loadOnboardingTemplates,
      workflow,
      profileTemplate,
      multipleShipments,
      oneTimeLink,
      validateTypeWorkflow,
      postOnboardingLink,
      onClose,
      openCreateLinkModal,
      type,
      translate,
    ]
  );
  useEffect(() => {
    loadAllProfileTemplates({ active: true });
  }, [loadAllProfileTemplates]);

  const validatePhone = (value) => {
    const phoneRegex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9]|[12]\d{2,})\)?\s?)(?:((?:9\s?\d|[2-9])\d{3})-?(\d{4}))$/;
    const firstTwoCharacters = value?.slice(0, 2);
    if (
      (!phoneRegex.test(value) && firstTwoCharacters === '55') ||
      (value?.length < 12 && value?.length > 3 && firstTwoCharacters === '55')
    ) {
      setInvalidPhoneNumber(true);
      return false;
    }
    setInvalidPhoneNumber(false);
    return true;
  };

  return (
    <>
      {CreateLinkModal}
      <div id="create-link-wrapper">
        <PageTitle title={customPageTitle?.title} subtitle={customPageTitle?.subtitle} />
        <Form
          validateTrigger="onBlur"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          className="pdd-top-10"
        >
          {loadingAllProfileTemplates || loadingWorkflows ? (
            <Spin className="flex center gx-w-100 mrg-top-50 mrg-btm-50" />
          ) : (
            <>
              <Form.Item
                name={
                  hasFeatureFlag('isWorkflowBuilder', user)
                    ? 'workflow'
                    : 'profileTemplate'
                }
                label={customProfileTemplateLabel}
                style={{ width: '100%' }}
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (hasFeatureFlag('isWorkflowBuilder', user)) {
                        return Promise.resolve();
                      }
                      const item = allRecursiveProfileTemplates?.find(
                        (profile) => profile?._id === value
                      );
                      const hasForbiddenService = item?.backgroundChecking?.services?.find(
                        (service) => ['pf_rais'].includes(service.source)
                      );

                      if (hasForbiddenService) {
                        return Promise.reject(
                          new Error(
                            translate(
                              'components.modalShareProfileTemplateLink.submit.messages.warn.profileTemplatePF'
                            )
                          )
                        );
                      }

                      if (item?.type === 'PF') {
                        return Promise.resolve();
                      }

                      if (value && !item?.qsaProfileTemplateId) {
                        return Promise.reject(
                          new Error(
                            translate(
                              `${I18N_BASE_PATH}.createLinkType.form.profileTemplate.rules.validator`
                            ) // => O link para onboarding empresarial requer um modelo de consulta com onboarding de sócio habilitado
                          )
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  showSearch
                  onSelect={
                    !hasFeatureFlag('isWorkflowBuilder', user)
                      ? (template) => handleSelectProfileTemplate(template)
                      : (wf) => selectWorkflow(wf)
                  }
                  optionFilterProp="children"
                  placeholder={
                    !hasFeatureFlag('isWorkflowBuilder', user)
                      ? translate(
                          `${I18N_BASE_PATH}.createLinkType.form.profileTemplate.placeholder` // => Selecione o modelo
                        )
                      : translate(
                          `${I18N_BASE_PATH}.createLinkType.form.profileTemplate.placeholderWorkflow` // => Selecione o workflow que deseja atrelar
                        )
                  }
                  className="select-profile-template"
                >
                  {groupedProfileTemplates?.pfProfileTemplates &&
                    showProfileTemplateLabelType?.showPFProfileTemplates &&
                    !hasFeatureFlag('isWorkflowBuilder', user) && (
                      <OptGroup
                        label={translate(
                          `${I18N_BASE_PATH}.createLinkType.form.profileTemplate.optGroup.person` // => Pessoa Física
                        )}
                      >
                        {groupedProfileTemplates?.pfProfileTemplates?.map((template) => (
                          <Option key={template._id} value={template._id}>
                            {template.name}
                          </Option>
                        ))}
                      </OptGroup>
                    )}
                  {groupedProfileTemplates?.pjProfileTemplates &&
                    showProfileTemplateLabelType?.showPJProfileTemplates &&
                    !hasFeatureFlag('isWorkflowBuilder', user) && (
                      <OptGroup
                        label={translate(
                          `${I18N_BASE_PATH}.createLinkType.form.profileTemplate.optGroup.company` // => Pessoa Jurídica
                        )}
                      >
                        {groupedProfileTemplates?.pjProfileTemplates?.map((template) => (
                          <Option key={template._id} value={template._id}>
                            {template.name}
                          </Option>
                        ))}
                      </OptGroup>
                    )}
                  {hasFeatureFlag('isWorkflowBuilder', user) && (
                    <OptGroup label="Workflow">
                      {workflowsData?.docs.map((wf) => (
                        <Option key={wf._id} value={wf._id}>
                          {wf.definition.name}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              </Form.Item>
              {!oneTimeLink && (
                <Form.Item name="multipleShipments">
                  <Checkbox
                    checked={multipleShipments}
                    onChange={() => setMultipleShipments(!multipleShipments)}
                  >
                    {translate(
                      `${I18N_BASE_PATH}.createLinkType.form.multipleShipments.text` // => Permitir múltiplos envios de documentos
                    )}
                  </Checkbox>
                </Form.Item>
              )}
              {hasFeatureFlag('onboardingOneTimeLink', user) && !multipleShipments && (
                <Form.Item name="oneTimeLink" className={oneTimeLinkClassName}>
                  <Checkbox
                    checked={oneTimeLink}
                    onChange={() => setOneTimeLink(!oneTimeLink)}
                  >
                    {translate(`${I18N_BASE_PATH}.createLinkType.form.oneTimeLink.text`)}
                  </Checkbox>
                </Form.Item>
              )}
              {type === 'profile' && (profileTemplate || workflow) && (
                <ProfileForm type={profileTemplate?.type || workflow?.segment} />
              )}
              <Row className="mrg-top-5 mrg-btm-10">
                <Col span={24} className="mrg-btm-10">
                  <strong className="text-dark" style={{ lineHeight: 2 }}>
                    {translate(
                      `${I18N_BASE_PATH}.createLinkType.form.shippingWay.text` // => Forma de envio
                    )}
                  </strong>
                  <Form.Item style={{ marginBottom: '0px' }} name="shippingWay">
                    <Checkbox.Group className="flex mrg-top-5">
                      <Checkbox
                        disabled
                        value="manual"
                        style={{ filter: 'grayscale(1)' }}
                      >
                        {translate(
                          `${I18N_BASE_PATH}.createLinkType.form.shippingWay.options.manual` // => Manual (Link)
                        )}
                      </Checkbox>
                      <Checkbox
                        value="email"
                        checked={emailCheckbox}
                        onChange={() => setEmailCheckbox(!emailCheckbox)}
                      >
                        {translate(
                          `${I18N_BASE_PATH}.createLinkType.form.shippingWay.options.email` // => Email
                        )}
                      </Checkbox>
                      <Checkbox
                        value="sms"
                        checked={smsCheckbox}
                        onChange={() => {
                          setInvalidPhoneNumber(!invalidPhoneNumber);
                          setSmsCheckbox(!smsCheckbox);
                        }}
                      >
                        {translate(
                          `${I18N_BASE_PATH}.createLinkType.form.shippingWay.options.sms` // => SMS
                        )}
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
              {emailCheckbox && (
                <Form.Item
                  name="email"
                  normalize={(value) => value?.trim()}
                  rules={[
                    { required: true, transform: (value) => value?.trim() },
                    {
                      type: 'email',
                      transform: (value) => value?.trim(),
                      message: translate('form.rules.emailMessage'),
                    },
                  ]}
                >
                  <Input
                    prefix={<i className="caf-ic_mail" />}
                    placeholder={translate(
                      `${I18N_BASE_PATH}.createLinkType.form.email.input.placeholder` // => Informe seu e-mail
                    )}
                  />
                </Form.Item>
              )}
              {smsCheckbox && (
                <>
                  <div className="no-mrg no-pdd input-phonenumber-2 flex-center">
                    <i
                      style={{ color: '#bdbdbd', marginLeft: '11px', marginRight: '4px' }}
                      className="caf-ic_smartphone"
                    />
                    <Form.Item
                      className="no-mrg no-pdd text-dark"
                      name="sms"
                      rules={[{ required: true }]}
                    >
                      <PhoneInput
                        localization={language[i18n.language]}
                        country={defaultCountry[i18n.language]}
                        countryCodeEditable={false}
                        onChange={(value) => validatePhone(value)}
                      />
                    </Form.Item>
                  </div>
                  <small className="font-size-12">
                    {translate(
                      `pages.private.profileModels.profileModelList.components.links.components.createLinkModal.components.createLinkType.index.createLinkType.form.shippingWay.options.smsWarning`
                    )}
                  </small>
                </>
              )}
              <div className="mrg-top-25 gx-flex-row gx-justify-content-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-link-btn"
                  loading={loadingOnboardingLink || loadingOnboardingTemplate}
                  disabled={
                    loadingOnboardingLink ||
                    loadingOnboardingTemplate ||
                    invalidPhoneNumber
                  }
                >
                  {translate(
                    `${I18N_BASE_PATH}.createLinkType.form.button.${
                      !loadingOnboardingLink || loadingOnboardingTemplate
                        ? 'default'
                        : 'loading' // => Criar link : Criando link...
                    }`
                  )}
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

CreateLinkType.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  segment: PropTypes.string,
};

CreateLinkType.defaultProps = {
  segment: undefined,
};

export default CreateLinkType;
