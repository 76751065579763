/* eslint-disable consistent-return */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import CAFLoader from 'components/Loader/CAFLoader';

import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import handleHubSelection from 'utils/handleHubSelection';
import { hasRequiredPermissions } from './permissionFunctions';

const SKY_TENANTS_IDS = [
  'c94b990c-1eab-40ea-bbdf-684635bc5af2', // sky
  '2ba38e96-5eb2-4e23-ac60-8bfbcbe41ae6', // sky beta
  '7991fee7-cb5a-45d0-9e1c-f6c2b9007823', // sky beta
  'c25c21d5-7ac8-4490-a147-c09f68eba950', // PS dev
  '8441880f-7371-429a-9450-1d7640de4ed6', // PS beta
];

const RouteWrapper = ({
  isPrivate = true,
  component: Component,
  or,
  requiredPermissions,
  onlyWithFlag,
  onlyWithoutFlag,
  customer,
  product = 'trust',
  ...rest
}) => {
  const { user, loadingAuth, getLoggedUser } = useAuth();
  const { loadingTheme, changeTheme, domain } = useTheme();

  const withFlag = onlyWithFlag && !hasFeatureFlag(onlyWithFlag, user);
  const withoutFlag = onlyWithoutFlag && hasFeatureFlag(onlyWithoutFlag, user);
  const flags = hasFeatureFlag(['isWorkflowBuilder', 'showGlobalDashboard'], user);

  useEffect(() => {
    // O state idle auxilia na troca rápida e limpeza de dados entre usuários no localhost
    if (user && user !== 'idle') return;

    getLoggedUser().then((data) => {
      changeTheme(data?.accountData?.domain);
    });
  }, [getLoggedUser, user, changeTheme]);

  const isLoading = useMemo(() => {
    return user === 'idle' || loadingAuth || loadingTheme;
  }, [user, loadingAuth, loadingTheme]);

  return isLoading ? (
    <CAFLoader />
  ) : (
    <Route
      {...rest}
      render={({ location }) => {
        const isLocalhost = !!window.location.origin.startsWith('http://localhost');
        const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

        const toEncode = isLocalhost
          ? `${location.pathname}${location.search}`
          : `${window.location.origin}${location.pathname}${location.search}`;

        const redirectUri = encodeURIComponent(toEncode);

        if (!user && location.pathname !== '/signin') {
          if (isLocalhost) {
            return <Redirect to={`/signin?continue=${redirectUri}`} />;
          }

          const disconnectedReason = Cookies.get('DISCONNECTED_REASON');

          window.location.href = `${
            isCafIo
              ? process.env.REACT_APP_BASE_URL_AUTH_WEB_NEW
              : process.env.REACT_APP_BASE_URL_AUTH_WEB
          }/?service=management&env=${
            process.env.REACT_APP_ENV
          }&continue=${redirectUri}&domain=${domain}&disconnectedReason=${
            disconnectedReason ?? ''
          }`;
          return;
        }
        // Comment this if you want to run prod in localhost
        if (process.env.REACT_APP_ENV === 'prod' && isPrivate) {
          if (
            user?.accountData &&
            !user?.accountData?.domain &&
            !window.location.origin.includes('trust')
          ) {
            if (isCafIo) {
              window.location.href = `${process.env.REACT_APP_BASE_URL_TRUST_WEB_NEW}${window.location.pathname}`;
            } else {
              window.location.href = `${process.env.REACT_APP_BASE_URL_TRUST_WEB}${window.location.pathname}`;
            }
            return;
          }
          if (
            user?.accountData?.domain &&
            user?.accountData?.domain !== 'caf' &&
            !window.location.origin.includes(user?.accountData?.domain)
          ) {
            window.location.href = `https://${user?.accountData?.domain}.combateafraude.com${window.location.pathname}`;
            return;
          }
        }

        // Impede usuário membro de um hub de contas de acessar qualquer rota que não seja /accounts
        const isHubMember =
          user?.hubData?.active && user?.hubData?.hubAccountsData?.length > 1;

        if (
          location.pathname !== '/accounts' &&
          handleHubSelection.shouldHubSelect() &&
          isHubMember
        ) {
          return <Redirect to="/accounts" />;
        }

        // Impede usuário de acessar signin se o mesmo não tiver em localhost
        if (location.pathname === '/signin') {
          if (!isLocalhost) {
            if (flags) return <Redirect to="/home" />;

            return <Redirect to="/dashboard" />;
          }
          return <Component />;
        }

        if (
          location.pathname.startsWith('/dashboard') &&
          !hasRequiredPermissions(user, ['metrics:read'], [], false, product)
        ) {
          if (hasRequiredPermissions(user, ['people:read'], [], false, product)) {
            return <Redirect to="/profile/people?_type=PF" />;
          }
          if (hasRequiredPermissions(user, ['companies:read'], [], false, product)) {
            return <Redirect to="/profile/companies?_type=PJ" />;
          }
        }

        if (requiredPermissions && requiredPermissions.length > 0) {
          const authorized = hasRequiredPermissions(
            user,
            requiredPermissions,
            [],
            or,
            product
          );

          if (!authorized) {
            return <Redirect to={{ pathname: '/unauthorized' }} />;
          }
        }

        if (customer === 'SKY' && !SKY_TENANTS_IDS.includes(user?.tenantId)) {
          return <Redirect to={{ pathname: '/unauthorized' }} />;
        }

        if (
          isPrivate &&
          !user?.accountData?.products?.trust?.active &&
          location.pathname !== '/unauthorized'
        ) {
          return <Redirect to={{ pathname: '/unauthorized' }} />;
        }

        if (withFlag || withoutFlag) {
          if (flags && location.pathname === '/dashboard') return <Redirect to="/home" />;

          return <Redirect to="/dashboard" />;
        }

        return <Component />;
      }}
    />
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  or: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  customer: PropTypes.string,
  product: PropTypes.string,
  onlyWithFlag: PropTypes.string,
  onlyWithoutFlag: PropTypes.string,
};

RouteWrapper.defaultProps = {
  customer: undefined,
  isPrivate: false,
  or: false,
  requiredPermissions: [],
  product: 'trust',
  onlyWithFlag: undefined,
  onlyWithoutFlag: undefined,
};

export default RouteWrapper;
